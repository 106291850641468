"use client";

import { Button, Link } from "@nextui-org/react";
import { useEffect, useState } from "react";

export default function CookieConsent() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="fadein fixed bottom-0 z-20 flex w-full flex-col gap-6 bg-neutral-950/80 px-6 py-4 text-neutral-50 backdrop-blur-md sm:m-6 sm:max-w-xl sm:rounded-xl">
      <p>
        Ta strona wykorzystuje pliki cookie w celu zapewnienia prawidłowego działania serwisu oraz w celach analitycznych. Klikając &apos;Akceptuję&apos;, wyrażasz zgodę na ich
        użycie. Więcej informacji znajdziesz w{" "}
        <Link href="/polityka-prywatnosci" underline="always" className="text-neutral-50">
          Polityce prywatności
        </Link>
        .
      </p>
      <Button className="w-full gap-2 bg-neutral-50 font-bold uppercase text-neutral-950" radius="full" onPress={acceptCookies}>
        Akceptuje
      </Button>
    </div>
  );
}
